function reloadTopCart() {
    $.ajax({
        type: "POST",
        url: routes.postReloadTopCart,
        data: {},
        dataType: "json",
        success: function(data) {
            $("#top_cart").empty().html(data.top)
        }
    })
};

var timeout;
function removeCart (rowid) {
    $.ajax ({
        type:"POST",
        url: routes.postRemoveCart,
        data: {
            "good_id" : rowid
        },
        success: function(data) {
            window.location.reload(true);
        }
    });
}

$(document).ready(function(){
// ------------------Добавление в корзину------------------------------
    $(document).on( 'click', '.js_btn_cart:not(.js_disabled)', function(e){
        e.stopPropagation();
        e.preventDefault();
        var lang = $('html').attr('lang');
        var good_id = $(this).attr('data-id');
        var count = $(this).attr('data-amount');
        var size  = $(this).attr('data-size');

        var el = $(this);
        if(el.hasClass('to_cart')){
            ajax_url = routes.postReloadGoodOrder;
        } else if (el.hasClass('in_cart')) {
            ajax_url = routes.postReloadGoodOrder;

            // ajax_url = routes.postShowGoodOrder;
        } else {
            ajax_url = routes.postReloadGoodOrder;
        }
        $(".js_modal_good_info").empty();
        $.ajax ({
            type: "POST",
            url: ajax_url,
            data: {
                "good_id": good_id,
                "count": count,
                "lang": lang,
                "size": size,
            },
            success: function(data) {
                reloadTopCart();
                $(".js_modal_good_info").html(data);
            },
        });
        if($(this).hasClass('to_cart')) {
            $(this).removeClass('to_cart').addClass('in_cart');
        }
    });
// ------------------Добавление в корзину------------------------------
// -----------AMOUNT-------------------------------
    $(document).on('click', '.amount_down_skill', function(){
        var $input = $(this).parent().find('input');
        var id = $input.attr('data-id');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $('.modal_amount_count[data-id="'+id+'"]').val(count);
        $('.js_input_send[name=amount]').val(count);
        $('.js_btn_cart').attr('data-amount',count);

        var price = $('.js_good_btn[data-price]').attr('data-price');
        var value = price * count;
        $('.js_modal_price').empty().text(value);

        return false;
    });
    $(document).on('click', '.amount_up_skill', function(){
        var $input = $(this).parent().find('input');
        var id = $input.attr('data-id');
        var count = parseInt($input.val()) + 1;
        $input.val(parseInt($input.val()) + 1);
        $('.modal_amount_count[data-id="'+id+'"]').val(count);
        $('.js_input_send[name=amount]').val(count);
        $('.js_btn_cart').attr('data-amount',count);

        var price = $('.js_good_btn[data-price]').attr('data-price');
        var value = price * count;
        $('.js_modal_price').empty().text(value);

        return false;
    });


    $(document).on('click', '.amount_down', function(){
        var $input = $(this).parent().find('input');
        var id = $input.attr('data-id');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $('.amount_count[data-id="'+id+'"]').val(count);
        $('.amount_count[data-id="'+id+'"]').change();
        $('.js_input_send[name=amount]').val(count);
        $('.js_btn_cart').attr('data-amount',count);
        return false;
    });
    $(document).on('click', '.amount_up', function(){
        var $input = $(this).parent().find('input');
        var id = $input.attr('data-id');
        var count = parseInt($input.val()) + 1;
        $('.amount_count[data-id="'+id+'"]').val(count);
        $('.amount_count[data-id="'+id+'"]').change();
        $('.js_input_send[name=amount]').val(count);
        $('.js_btn_cart').attr('data-amount',count);
        return false;
    });
    $(document).on('change', '.amount_count', function() {
        var $el = $(this);
        var rowid = $el.attr('data-id');
        window.clearTimeout(timeout);
        timeout = window.setTimeout(function() {
            var qty = $el.val();
            if (qty == 0) {
                removeCart(rowid)
            } else {

                $.ajax ({
                    type:"POST",
                    url:routes.postUpdateCart,
                    data: {
                        "good_id": rowid,
                        "count": qty,
                    },
                    dataType:"json",
                    success: function(data) {
                        $.ajax ({
                            type:"POST",
                            url: routes.postReloadTopCart,
                            data: {},
                            dataType:"json",
                            success: function(data) {
                                $("#top_cart").empty().html(data.top);
                            }
                        });
                        $el.parent().parent().parent().parent().next().find('.cart-subtotal').html(data.subtotal);
                        $(".js_cart_subtotal").html(data.subtotal);
                        $('#cart_all_total').html(data.total);
                        $("#cart_all_count").html(data.count);
                    }
                });
            }
        }, 1000);
    });
// -----------AMOUNT-------------------------------
// ------------Удаление из корзины ------------------------
    $(document).on('click', '.js_remove_cart', function() {
        var rowid = $(this).attr('data-id');
        var $el = $(this).closest('tr');
        $.ajax ({
            type:"POST",
            url: routes.postRemoveCart,
            data: {
                "good_id":rowid
            },
            success: function(data) {
                window.location.reload(true);
            }
        });
    });
// ------------Удаление из корзины ------------------------
});
