// Fixed Header !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1
    var header = document.querySelector(".js_header_bottom"),
        trueHeader = document.querySelector(".js_header_desctop");
    if (header) {
        var sticky = header.offsetTop;
    }
    function headerFixed () {
        var padding = header.offsetHeight;
        if (window.pageYOffset >= sticky) {
            header.classList.add("js_fixed");
            trueHeader.style.paddingBottom = '' + padding + 'px';
        } else {
            header.classList.remove("js_fixed");
            trueHeader.style.paddingBottom = '0';
        }
    }
// TABS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    function funcTabs (event) {
        var event = event.target;
        var parentTabs = this.parentNode;
        if (event.classList.contains('js_tab_btn')) {
            var dataBtn = event.getAttribute('data-tab');
            var btnTabs = parentTabs.querySelectorAll('.js_tab_btn');
            for (var i = 0; i < btnTabs.length; i++) {
                btnTabs[i].classList.remove('active-tab');
            }
            event.classList.add('active-tab');
            var dataTabOther = parentTabs.querySelectorAll('[data-tab= "'+ dataBtn +'"]');
            for (var i = 0; i < dataTabOther.length; i++) {
                if(!dataTabOther[i].classList.contains('active-tab')) {
                    dataTabOther[i].classList.add('active-tab');
                }
            }
            var tabBody = parentTabs.getElementsByClassName('js_tabs_panel');
            for (var i = 0; i < tabBody.length; i ++) {
                var dataBlock = tabBody[i].getAttribute('data-block');
                tabBody[i].classList.remove('active-tab');
                if (dataBtn == dataBlock) {
                    tabBody[i].classList.add('active-tab');
                }
            }
        }
    }

    function tabsStart () {
    $('.js_tabs_btn').each(function() {
        $(this).find('.js_tab_btn').removeClass('active-tab');
        $(this).find('.js_tabs_panel').removeClass('active-tab');
        $(this).find('.js_tab_btn:first').addClass('active-tab');
        $(this).find('.js_tabs_panel:first').addClass('active-tab');
        if ($(this).find('.js_tabs_panel:first').find('.js_good__tile_slick').is('.slick-slider') == true) {
            $(".js_good__tile_slick").slick('destroy');
            $(".js_good__tile_slick").slick('refresh');
        }
    });
}
// --------------------------------------------------------------------
function activeFiltersAppend () {
    if($(window).width() >=1199) {
        $('.filter__item_choose').insertBefore(".filters__form");
    } else if ($(window).width() < 1199){
        if($('.filter__item_choose_cover').children('.filter__item_choose').length == 0) {
            $('.filter__item_choose_cover').append($(".filter__item_choose"));
        }
    }
}

var tempScrollTop, currentScrollTop = 0;
$(window).on('load scroll', function () {
    if(header) {
        headerFixed ();
    }
    var windowTop = $(this).scrollTop(),
        windowH = $(window).height(),
        headerMobile = $('.js_header_mobile'),
        headerH = headerMobile.height();
    if (windowTop >= headerH) {
        $('.js_header_mobile:not(.is_active)').removeAttr('style');
        $('.js_header_mobile:not(.is_active)').removeClass('up__top');
    }
    if (tempScrollTop < windowTop ) {
        if (windowTop >= headerH) {
            $('.js_header_mobile:not(.is_active)').addClass('up__top')
            $('.js_header_mobile.up__top:not(.is_active)').css({
                'top': -headerH
            })
        } else {
            $('.js_header_mobile:not(.is_active)').removeAttr('style');
            $('.js_header_mobile:not(.is_active)').removeClass('up__top');
        }
    }
    else if (tempScrollTop > windowTop ) {
        $('.js_header_mobile:not(.is_active)').removeClass('up__top')
    }
    tempScrollTop = windowTop;
});


$(window).on('load', function () {
    $('#preloader').delay(350).fadeOut('slow');
    if(header) {
        headerFixed ();
    }
    setTimeout(function() {
          $(".input_input").each(function(){
              if($(this).val() != '') {
                  $(this).next('.input_placeholder').css({"top":"-10px", "color":"#262626"});
                  $(this).addClass('active_input');
              }
          });
      }, 500);
});

$(window).on('resize', function () {
	var width = $(window).width();
     if(width >=1200) {
         $('.footer').find('.js_block').attr('style', '');
         $('.js_overlay').removeClass("js_active");
         $('body').removeClass('overflow');
     }
     activeFiltersAppend ();
});

window.onscroll = function(){
    if ($(this).scrollTop() > 350){
        $('#up_button').addClass('js_visible');
    }
    else{
        $('#up_button').removeClass('js_visible');
    }
};
window.onload = function () {
    if ($(this).scrollTop() > 350){
        $('#up_button').addClass('js_visible');
    }
    else{
        $('#up_button').removeClass('js_visible');
    }
};

$(document).mouseup(function (e){
    var div = $(".js_size_select_cover");
    if (!div.is(e.target) && div.has(e.target).length === 0) {
        if($('.size_select').hasClass('js_active')) {
            $('.size_select').removeClass('js_active');
            $('.size_block').slideUp(400, function() {
                $(this).removeClass('js_active');
            });
        }
    }
});

$(document).ready(function(){
// -----Главный слайдер-----------------------------------

// -----Главный слайдер-----------------------------------
//-----------------Перебор пустых фильтров----------------------------
    $('.js_filter_list').each(function() {
        if($(this).children().length == 0) {
            $(this).parents('.js_filter_item').addClass('js_hide');
        } else {
            $(this).parents('.js_filter_item').removeClass('js_hide');
        }
    });
//-----------------Перебор пустых фильтров----------------------------
// ---------------Кнопка наверх --------------------------------
    $("#up_button").on("click", function(e) {
           e.stopPropagation();
           e.preventDefault();
           $('html, body').animate({
               scrollTop: 0
           }, 500)
     });
// ---------------Кнопка наверх --------------------------------
// -----------------Фильтр на адптиве ----------------------------
    activeFiltersAppend ();
// -----------------Фильтр на адптиве ----------------------------
// ---------------------- Блог лист --------------------------
    $('.news__tile_container').masonry({
       itemSelector: '.news__tile_cover',
   });
// ---------------------- Блог лист --------------------------
// -----Дозагрузка фоток начало------------------------------
    $(".lazy").lazyload({
        effect : "fadeIn",
        threshold : 500,
        forceLoad: true,
        skip_invisible : true,
    });
// -----Дозагрузка фоток конец------------------------------
// -------------------- Звезды на странице отзывов--------------------------
//   $(".js_rating").each(function() {
//     $(this).rateYo({
//       rating: $(this).attr('data-rate'),
//       starWidth: "16px",
//       ratedFill: '#dfdb6c',
//       figureFill: '#ebebeb',
//       spacing: "5px",
//       normalFill: '#ebebeb',
//       readOnly: true,
//     });
// });
// -------------------- Звезды на странице отзывов--------------------------
// ------------------------------Бургер-меню ------------------------------------
     $('.js_menu_btn_mobile').click(function () {
         if($('.js_filter').hasClass('js_active')) {
             $('.js_filter').removeClass('js_active');
             $('.js_overlay').removeClass("js_active");
             $('body').removeClass('overflow');
         }
         $('.js_menu_btn_mobile').toggleClass('js_active');
         $('.js_mobile_menu').toggleClass('js_active');
         $('.site').toggleClass('js_active');
         $('body').toggleClass('overflow');
     });
     $(document).on('click', '.js_mobile_menu', function(e) {
         if ($(e.target).is('.js_mobile_menu')) {
             $('.js_mobile_menu').removeClass('js_active');
             $('.js_menu_btn_mobile').removeClass('js_active');
             $('.site').removeClass('js_active');
             $('body').removeClass('overflow');
         }
     });
// ----------------------------Бургер-меню -----------------------------
// ------------ Обрезка текста по первым 500 сиволам-------------------------
// $(".js_ellips").text(function(i, text) {
//       if (text.length >= 500) {
//         text = text.substring(0, 500);
//         var lastIndex = text.lastIndexOf(" ");
//         text = text.substring(0, lastIndex) + '...';
//       }
//   $(this).text(text);
// });
// ------------ Обрезка текста по первым 500 сиволам-------------------------
// ---- Проверка на наличие вариантов ------------------------------------
if($('.js_variants').length == 0 || $('.js_variant_item').length > 0) {
    $('.js_modal_btn').removeClass('js_disabled');
    var sizeChoose = $('.js_variant_item.select_link').attr('data-size');
    $('.js_variant_active').empty().text(sizeChoose);
    $('.js_good_btn_not_size').attr('data-size', sizeChoose)
}

$(document).on('click', '.js_good_btn:not(.js_disabled)', function(e){
    e.stopPropagation();
    e.preventDefault();
    if($(this).hasClass('js_size_item')) {
        var id = $(this).attr('data-good-id'),
        good_name = $(this).attr('data-good-name'),
        good_code = $(this).attr('data-good-code');
        $('.js_input_send[name=good_id]').val(id);
        $('.js_modal_good_code').text(good_code);
        $('.js_modal_good_name').text(good_name);
        $('.modal_good_span').hide();
    } else {
        $('.js_modal_good_code').empty();
        $('.js_modal_good_name').empty();
        $('.modal_good_span').show();
    }
    var name    = $(this).attr('data-name'),
    type    = $(this).attr('data-type'),
    visible = $(this).attr('data-visible'),
    subject = $(this).attr('data-subject'),
    id      = $(this).attr('data-good-id'),
    size    = $(this).attr('data-size');


    $('.input_wrapper').removeClass('.forms__error');
    // передаем название модалки
    $('.js_modal_header').empty().text(name);

    // передаем тип субъетка в форму
    $('.js_modal_form').attr('data-subject',subject);

    // Открываем нужный блок футера в модалке
    $('.js_modal_footer').addClass('modal__footer_hidden');
    $('.js_modal_footer[data-visible="'+visible+'"]').removeClass('modal__footer_hidden');
    $('.js_modal_good_item').addClass('js_hide');
    $('.js_modal_good_item[data-visible="'+visible+'"]').removeClass('js_hide');
    // передаем в скрытый инпут тип заказа
    $('.js_input_send[name=type]').val(type);
    $('.js_input_send[name=size]').val(size);
    $('.js_input_send[name=good_id]').val(id);
    $('.modal_size').empty().text(size);
    if($('.js_modal_price').text() == "") {
        var price = $('.js_good_btn[data-price]').attr('data-price');
        $('.js_modal_price').empty().text(price);
    }
});

$(document).on('click', '.js_modal_btn.js_disabled', function(e) {
    e.stopPropagation();
    e.preventDefault();
    $('.size_select').addClass('size_error');
});
// ---------------Закрытие модалки----------------------------------
$(document).on('click', '.js_close_modal', function() {
    $.magnificPopup.close();
});
// ---------------Закрытие модалки----------------------------------

// ---- Проверка на наличие вариантов ------------------------------------
//------------------ Modalki maginific ---------------------------------
    $('.js_modal_btn:not(.js_disabled)').magnificPopup({
        fixedContentPos: true,
    });
//------------------ Modalki maginific ---------------------------------
// -------------------------Галерея магнифик -----------------------
    $('.js_magnific').magnificPopup({
      type: 'image',
      fixedContentPos: true,
      gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0,1],
      },
    });
// -------------------------Галерея магнифик -----------------------
// --------------Стилизация селекта----------------------------------------
$('.js_select').selectric({
      disableOnMobile: false,
      nativeOnMobile: false,
  });
 // --------------Стилизация селекта----------------------------------------
 // ---------------breadcrumbs-----------------------------------------------
 $(".js-lastcrumb").click(function(e) {
      e.stopPropagation();
      e.preventDefault();
 })
 // -----------------breadcrumbs-----------------------------------------------
// -------------Клик и открытие соседненго блока -------------------------------------
$(document).on( 'click', '.js_btn', function(e){
    $(this).toggleClass('js_active');
    $(this).next('.js_block').slideToggle(400, function() {
        $(this).toggleClass('js_active');
    });

});

$(document).on( 'click', '.js_btn_accord', function(e){
    if(!$(this).hasClass('js_active')) {
        $('.js_btn_accord').removeClass('js_active');
        $('.js_block_accord').slideUp(400, function() {
            $(this).removeClass('js_active');
        });
        $(this).addClass('js_active');
        $(this).next('.js_block_accord').slideDown(400, function() {
            $(this).addClass('js_active');
        });
    } else {
        $(this).removeClass('js_active');
        $(this).next('.js_block_accord').slideUp(400, function() {
            $(this).removeClass('js_active');
        });
    }
});

var block3d = document.getElementById('mySpriteSpin');
if(block3d) {
    var source3d = $("#mySpriteSpin").attr('data-source');
    source3d =  source3d.split(',');
    source3d.pop();
}

$(document).on( 'click', '.js_btn_click', function(e){
    $(this).toggleClass('js_active');
    var block = $(this).attr('data-block');
    $('.js_block_click[data-block='+block+']').slideToggle(400, function() {
        $(this).toggleClass('js_active');
    });
   if(block3d) {
        if($(this).hasClass('good_3d')) {
            var iframe_size = 1000;
			if (document.body.clientWidth <1024) {iframe_size = 700;};
			if (document.body.clientWidth<768) {iframe_size = 300;};
            $("#mySpriteSpin").spritespin({
                source: source3d,
                frameTime : 500,
                frameStep : 1,
                width   : iframe_size,
				height  : iframe_size/1.25,
                sense : 1,
                animate : true,
                loop: true,
                frameWrap : true,
                frameStep : 1,
                reverse : true,
                enableCanvas : false
            });
        } else {
            $("#mySpriteSpin").spritespin("destroy");
        }
    }
});

// -------------Клик и открытие соседненго блока -------------------------------------
// ----------TABS -------------------------------------
tabsStart ();
var btnsTabs = document.querySelectorAll('.js_tabs_btn');
  if (btnsTabs) {
      for (var i = 0; i< btnsTabs.length; i++) {
          btnsTabs[i].addEventListener('click', funcTabs);
      }
 };
//-------------TABS-------------------------------------
// ------------ открытие и закрытие филтра на адаптиве----------------------
$(document).on('click', '.js_open_filter', function(){
    $('.js_filter').toggleClass("js_active");
    $('.js_overlay').toggleClass("js_active");
     $('body').toggleClass('overflow');
});

$(document).on('click', '.js_overlay', function(){
    $('.js_filter').removeClass("js_active");
    $('.js_overlay').removeClass("js_active");
     $('body').removeClass('overflow');
});


// ------------ открытие и закрытие филтра на адаптиве----------------------
// -----------------Слайдер на странице товара --------------------
$('.js_good_big_slick').slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      swipe: true,
      fade: true,
      lazyLoad: 'ondemand',
      asNavFor: '.js_good_mini_slick',
      prevArrow: '<div class="slick-good_tile_prev slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.48 36.89"><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><g id="Group_1288" data-name="Group 1288"><line id="Line_472" data-name="Line 472" class="cls-1" x1="20.48" y1="35.89" x2="1" y2="18.44"/><line id="Line_473" data-name="Line 473" class="cls-1" x1="20.48" y1="1" x2="1" y2="18.44"/></g></g></g></svg></div>',
      nextArrow: '<div class="slick-good_tile_next slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.48 36.89"><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><g id="Group_1288" data-name="Group 1288"><line id="Line_472" data-name="Line 472" class="cls-1" x1="1" y1="35.89" x2="20.48" y2="18.44"/><line id="Line_473" data-name="Line 473"             class="cls-1" x1="1" y1="1" x2="20.48" y2="18.44"/></g></g></g></svg></div>',
      responsive:[
              {
                  breakpoint : 481,
                  settings: {
                     arrows: true,
                  }
              },
           ]
  });
  $('.js_good_mini_slick').slick({
      asNavFor: '.js_good_big_slick',
      dots: false,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
      focusOnSelect: true,
      vertical: true,
      draggable: true,
      lazyLoad: 'ondemand',
      prevArrow: '<div class="slick-good_mini_prev slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13"><g><g><g><path fill="none" stroke="#1a1f22" stroke-linecap="round" stroke-miterlimit="50" stroke-width="1.5" d="M21.404 11.927L11.505.87"/></g><g><path fill="none" stroke="#1a1f22" stroke-linecap="round" stroke-miterlimit="50" stroke-width="1.5" d="M1.606 11.927L11.506.87"/></g></g></g></svg></div>',
      nextArrow: '<div class="slick-good_mini_next slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13"><g><g><g><path fill="none" stroke="#1a1f22" stroke-linecap="round" stroke-miterlimit="50" stroke-width="1.5" d="M1.607.871l9.9 11.056"/></g><g><path fill="none" stroke="#1a1f22" stroke-linecap="round" stroke-miterlimit="50" stroke-width="1.5" d="M21.405.871l-9.899 11.056"/></g></g></g></svg></div>',
      responsive:[
              {
                  breakpoint : 1200,
                  settings: {
                      slidesToShow: 3,
                      vertical: false
                  }
              },
              {
                  breakpoint : 1024,
                  settings: {
                      slidesToShow: 5,
                      vertical: false
                  }
              },
              {
                  breakpoint : 768,
                  settings: {
                      slidesToShow: 4,
                      vertical: false
                  }
              },
           ]
  });
// -----------------Слайдер на странице товара --------------------
// ----Слайдер отзывов на главной --------------------
$('.review__block_slick').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite:true,
    draggable: true,
    arrows: false,
    dots: true,
    fade: true,
    speed: 1000,
    lazyLoad: 'ondemand',
});
// ----Слайдер отзывов на главной --------------------
// --- Слайдер товаров--------------------------------
$('.js_good_carusel').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    draggable: true,
    arrows: false,
    dots: true,
    speed: 1000,
    lazyLoad: 'ondemand',
    responsive:[
            {
                breakpoint : 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint : 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint : 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint : 481,
                settings: {
                   slidesToShow: 1,
                   slidesToScroll: 1
                 }
             }
         ]
});
// --- Слайдер товаров--------------------------------
// --- Слайдер товаров Connect--------------------------------
$('.js_good_carusel_connect').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    draggable: true,
    arrows: false,
    dots: true,
    speed: 1000,
    lazyLoad: 'ondemand',
});
// --- Слайдер товаров Connect--------------------------------
// ---------------------Слайдер похожих товаров----------------------
$('.js_similar_goods').slick({
      dots: false,
      arrows: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: true,
      swipe: true,
      speed: 1000,
      // lazyLoad: 'ondemand',
      asNavFor: '.js_similar_nav',
      responsive:[
              {
                  breakpoint : 1200,
                  settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1
                  }
              },
              {
                  breakpoint : 1024,
                  settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1
                  }
              },
              {
                  breakpoint : 768,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1
                  }
              },
              {
                  breakpoint : 481,
                  settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1
                   }
               }
           ]
  });
  $('.js_similar_nav').slick({
      asNavFor: '.js_similar_goods',
      // lazyLoad: 'ondemand',
      dots: false,
      arrows: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: true,
      centerMode: false,
      focusOnSelect: true,
      draggable: true,
      variableWidth: true,
  });

// ---------------------Слайдер похожих товаров----------------------
// -----Слайдер good_tile----------------------------
$('.js_good__tile_slick').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite:false,
    draggable: true,
    arrows: true,
    prevArrow: '<div class="slick-good_tile_prev slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.48 36.89"><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><g id="Group_1288" data-name="Group 1288"><line id="Line_472" data-name="Line 472" class="cls-1" x1="20.48" y1="35.89" x2="1" y2="18.44"/><line id="Line_473" data-name="Line 473" class="cls-1" x1="20.48" y1="1" x2="1" y2="18.44"/></g></g></g></svg></div>',
    nextArrow: '<div class="slick-good_tile_next slider-controls slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.48 36.89"><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><g id="Group_1288" data-name="Group 1288"><line id="Line_472" data-name="Line 472" class="cls-1" x1="1" y1="35.89" x2="20.48" y2="18.44"/><line id="Line_473" data-name="Line 473"             class="cls-1" x1="1" y1="1" x2="20.48" y2="18.44"/></g></g></g></svg></div>',
    dots: false,
    swipe: false,
    speed: 1000,
    lazyLoad: 'ondemand',
});

$('.js_good__tile_slick').on('beforeChange', function(event, slick, direction){
    var childrenSlick = slick.$slider.find('.lazy_click');
    childrenSlick.lazyload({
        threshold : 500,
        forceLoad: true,
        skip_invisible : true,
    });
});


// -----Слайдер good_tile----------------------------
// ------Добавление товара в избранные на good_tile------------------------
$(document).on('click', '.js_add_wishlist', function() {
    var good_id = $(this).attr('data-id');
    var lang = $('html').attr('lang');
    $.post(routes.postAddGoodInListToWishList, { 'good_id' : good_id, 'lang' : lang },
    function(data) {
        var good_id = data.result.id,
            wish_icon = data.result.icon,
            wish_count = data.wish_count,
            header_icon = data.result.wishlist,
            header_counter = $('.js_counter_wishlist'),
            header_wishlist = $('.js_wish_header'),
            good_tile = $('.js_good_tile').find('.js_good_tile_wishlist[data-id="'+ good_id +'"]');
            good_tile.empty();
            good_tile.append(wish_icon);
            if(header_counter.length) {
                header_counter.empty().text(wish_count);
            } else {
                header_wishlist.empty();
                header_wishlist.append(header_icon);
            }
    });
});
// ------Добавление товара в избранные на good_tile------------------------
// ------Удаление товара в избранные на good_tile------------------------
    $(document).on('click', '.js_del_wishlist', function() {
        var good_id = $(this).attr('data-id');
        var lang = $('html').attr('lang');
        $.post(routes.postRemoveWishGood, { 'good_id' : good_id, 'lang' : lang },
        function(data) {
            var good_id = data.result.id,
                wish_icon = data.result.icon,
                wish_count = data.wish_count,
                header_icon = data.result.wishlist,
                header_counter = $('.js_counter_wishlist'),
                header_wishlist = $('.js_wish_header'),
                good_tile = $('.js_good_tile').find('.js_good_tile_wishlist[data-id="'+ good_id +'"]');
                good_tile.empty();
                good_tile.append(wish_icon);
                if(wish_count == 0) {
                    header_wishlist.empty();
                    header_wishlist.append(header_icon);
                } else {
                    header_counter.empty().text(wish_count);
                }
        });
    });
// ------Удаление товара в избранные на good_tile------------------------
});
