import $ from 'jquery';
window.$ = window.jQuery = $;

require('./modernizr-custom');
require("jquery-lazyload");
require("slick-carousel");
require('magnific-popup');
require('selectric');
require('inputmask');
require('jquery-validation');
require('rateyo');
require('spritespin');

import noUiSlider from "nouislider/distribute/nouislider.min";
window.noUiSlider = require('nouislider');
import wNumb from "wnumb/wNumb.min";
window.wNumb = require('wnumb');

import masonry from "masonry-layout/dist/masonry.pkgd.min";
window.masonry = require('masonry-layout');

require('./master');
require('./forms');
require('./cart');
require('./cart-order');
require('./cabinet');

//cabinet
require("bootstrap");
require('x-editable/dist/bootstrap3-editable/js/bootstrap-editable');
require('bootstrap-switch');
require('pgenerator');
