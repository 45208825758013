$(document).ready(function(){
// --------------------------Вход в кабинет ---------------------------
    $("#login_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input_block'));
        },
        highlight: function(element) {
            $(element).parents('.input_wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input_wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#login_form").serialize();
            $.ajax({
                url: routes.postLogin,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                success: function(data) {
                    switch (data) {
                        case "wrong_pass":
                            $("#login_form .auth-error").slideDown(200);
                            break;
                        case "success":
                            window.location.reload(true);
                            break
                    }
                }
            });
        }
    });

    $("#do_login").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $("#login_form").submit();
    });

// ----------------------Вход в кабинет ------------------------------
// -----------------------Регистрация -----------------------------------------
$("#reg_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
          errorPlacement: function(error, element) {
              error.appendTo($(element).parents('.input_block'));
          },
          highlight: function(element) {
              $(element).parents('.input_wrapper').addClass("forms__error");
          },
          unhighlight: function(element) {
              $(element).parents('.input_wrapper').removeClass("forms__error");
          },
          submitHandler: function(form) {
              var csrf_token = $('meta[name="csrf-token"]').attr('content');
              var formdata = $("#reg_form").serialize();
              $.ajax({
                  url: routes.postRegister,
                  type: 'POST',
                  data: {
                      "_token" : csrf_token,
                      "data": formdata,
                  },
                  success: function(data) {
                      switch (data) {
                        case "email":
                            $("#reg_form .auth-error").slideDown(200);
                            break;
                        case "deleted":
                            $("#reg_form .auth-del").slideDown(200);
                            break;
                        case "success":
                            window.location.href = window.location.origin + "/cabinet/";
                            break
                      }
                  }
              })
          }
    });
    $('#do_reg').click(function(e) {
        e.preventDefault();
        $("#reg_form").submit()
    });
// -----------------------Регистрация -----------------------------------------
// -------------------------------Сброс пароля -----------------------------
    $("#reset_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input_block'));
        },
        highlight: function(element) {
            $(element).parents('.input_wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input_wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#reset_form").serialize();
            $.ajax({
                url: routes.postPassword,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                success: function(data) {
                    switch (data) {
                        case "no_email":
                            $("#reset_form .auth-error").slideDown(200);
                            $("#reset_form .auth-restored").slideUp(200);
                            break;
                        case "success":
                            $("#no_email").addClass("hide");
                            $("#reset_form .auth-restored").slideDown(200);
                            $("#reset_form .auth-error").slideUp(200);
                            setTimeout(function() {
                                $.magnificPopup.close();
                            }, 2000);
                            break
                    }
                }
            });
        }
    });
    $("#do_reset").click(function(e) {
        e.preventDefault();
        $("#reset_form").submit()
    });
// -------------------------------Сброс пароля -----------------------------
//  ------------------------Выход из кабинета-----------------------------
    $(".a-logout").click(function(e) {
      e.stopPropagation();
      e.preventDefault();
      var csrf_token = $('meta[name="csrf-token"]').attr('content');
      $.ajax({
          url: routes.postLogout,
          type: 'POST',
          data: {
              "_token" : csrf_token,
          },
          success: function(data) {
              switch (data) {
                  case "success":
                    var href = window.location.href;
                    var return_href = href.split('#')[0];

                      window.location.href = return_href;
                      break
              }
          }
      });
    });
//  ------------------------Выход из кабинета-----------------------------
});
