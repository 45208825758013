///checkbox handler
$(".address_toggler").change(function (e) {
    if ($(this).is(":checked")) {
        $(".address_variants").slideUp(400);
        $(".address_variants").find('.input_input').each(function() {
			$(this).prop('disabled', true);
		});
        var paymentsMethods = $(this).attr('data-payments').split(',');
        $('.payment_radio').prop('disabled', false);
        $('.payment_radio').each(function () {
            var value = $(this).attr('data-payment');
            if (paymentsMethods.indexOf(value) == -1) {
                $(this).prop('disabled', true);
                if($(this).is( ":checked" ) ){
                    $('[data-payment="' + paymentsMethods[0]  + '"]').prop('checked', true);
                }
            }
        });
        $("#" + $(this).val()).slideDown(400);
        $("#"+$(this).val()).find('.input_input').each(function() {
			$(this).prop('disabled', false);
		});
    }
    if(  $('[data-payment="bank_transfer"]').prop('disabled') ){
        $('.bank-block').slideUp(150);
    }
});

$(".address_toggler").trigger("change");

$('.payment_radio').change(function (e) {
    if ($(this).attr('data-payment') == "bank_transfer") {
        $('.bank-block').slideDown(150);
    } else {
        $('.bank-block').slideUp(150);
    }
});
$('.bank-radio').change(function (e) {
    if ($(this).attr('id') == "entity") {
        $('#faceless_items').slideDown(150);
    } else {
        $('#faceless_items').slideUp(150);
    }
})

///checkbox handler
$("#order_checkbox").change(function() {
	if(this.checked){
		$("#do_order_btn").removeClass('js_disabled');
		$('.js_label-two').addClass('js_hide');
		$('.js_label-one').removeClass('js_hide');
	} else {
		$("#do_order_btn").addClass('js_disabled');
	}
});

$("#do_order_btn").click(function (e) {
	e.stopPropagation();
	e.preventDefault();
	if(!$(this).hasClass('js_disabled')) {
		$("#order_form").submit();
		$('.js_label-two').addClass('js_hide');
		$('.js_label-one').removeClass('js_hide');
	} else {
		$('.js_label-one').addClass('js_hide');
		$('.js_label-two').removeClass('js_hide');
	}
});


$("#order_form").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input_block'));
    },
    highlight: function(element) {
        $(element).parents('.input_wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input_wrapper').removeClass("forms__error");
    },
    submitHandler: function (form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#order_form").serialize();
        $("#order_form")[0].reset();
        $('#do_order_btn').addClass('js_hide');
        $('.ajax-loader').removeClass('js_hide');
        $.ajax({
            url: routes.postDoOrder,
            type: 'POST',
            data: {
                "_token": csrf_token,
                "data": formdata,
            },
            success: function () {
                window.location.href = '/stranica-blagodarnosti';
            },
            error: function () {
                alert('Что-то пошло не так. В корзине нет товаров, Вы будете перенаправлены на главную страницу');
                window.location = "/";
            }
        })
    }
});
