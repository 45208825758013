// Placeholder for input
function placeholderInput() {
    $(".input_input")
    .on("focus", function(){
        $(this).next('.input_placeholder').css({"top":"-10px", "color":"#262626"});
        $(this).addClass('active_input');
    })
    .on("blur", function(){
        if ($(this).val() == "") {
            $(this).next('.input_placeholder').css({"left":"15px", "top":"14px","color":"#000"});
            $(this).removeClass('active_input');
        }
    });
}

// Placeholder for input
$(document).ready(function(){
    placeholderInput();
    Inputmask({
        mask: "+38 (999) 999-99-99",
        clearMaskOnLostFocus: true,
        clearIncomplete: true,
        showMaskOnHover: false,
    }).mask('.phone');
// ---------------Обратный звонок -----------------------------
    $('#do_callback').click(function(e) {
      e.stopPropagation();
      e.preventDefault();
      $("#do_form_callback").submit();
    });

    $("#do_form_callback").validate({
      errorElement: 'span',
      errorClass: 'span__error',
      ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input_block'));
    },
    highlight: function(element) {
        $(element).parents('.input_wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input_wrapper').removeClass("forms__error");
    },
    submitHandler: function(form) {
          var csrf_token = $('meta[name="csrf-token"]').attr('content');
          var formdata = $("#do_form_callback").serialize();
          $("#do_form_callback")[0].reset();
          $.ajax({
              url: routes.postSend,
              type: 'POST',
              data: {
                  "_token" : csrf_token,
                  "data": formdata,
                  "subj": "callback"
              },
              success: function(data) {
              $("#callback_success").show(300);
                setTimeout(function() {
                    $("#callback_success").hide()
                }, 4000)
                setTimeout(function() {
                    $.magnificPopup.close();
                    $('.input_input').next('.input_placeholder').css({"left":"15px", "top":"14px","color":"#000"});
                    $('.input_input').removeClass('active_input');
                }, 3000)
              }
          })
      }
    });
// ---------------Обратный звонок -----------------------------
// -----------Оптовы покупателям---------------------------------
    $('#do_opt_reg').click(function(e) {
          e.stopPropagation();
          e.preventDefault();
          $("#opt_reg_form").submit();
    });
    $("#opt_reg_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input_block'));
        },
        highlight: function(element) {
            $(element).parents('.input_wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input_wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#opt_reg_form").serialize();
            $.ajax({
                url: routes.postPhoneLogin,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                success: function(data) {
                    switch (data) {
                        case "wrong_user":
                            $("#opt_reg_form .wrong_user").slideDown(200);
                            break;
                        case "wrong_pass":
                            $("#opt_reg_form .wrong_pass").slideDown(200);
                            break;
                        case "success":
                            window.location.reload(true);
                            break
                    }
                }
            });
        }
    });
    $('#do_opt_reg_modal').click(function(e) {
          e.stopPropagation();
          e.preventDefault();
          $("#opt_reg_form_modal").submit();
    });
    $("#opt_reg_form_modal").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input_block'));
        },
        highlight: function(element) {
            $(element).parents('.input_wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input_wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#opt_reg_form_modal").serialize();
            $.ajax({
                url: routes.postPhoneLogin,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                success: function(data) {
                    switch (data) {
                        case "wrong_user":
                            $("#opt_reg_form_modal .wrong_user").slideDown(200);
                            break;
                        case "wrong_pass":
                            $("#opt_reg_form_modal .wrong_pass").slideDown(200);
                            break;
                        case "success":                            
                            window.location.reload(true);
                            break
                    }
                }
            });
        }
    });
// -----------Оптовы покупателям---------------------------------
//  Отправка формы из модалки на наличие товара, или его цену,
    $('#do_btn_request').click(function(e) {
      e.stopPropagation();
      e.preventDefault();
      $("#do_form_request").submit();
   });

   $("#do_form_request").validate({
      errorElement: 'span',
      errorClass: 'span__error',
      ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input_block'));
    },
    highlight: function(element) {
        $(element).parents('.input_wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input_wrapper').removeClass("forms__error");
    },
      submitHandler: function(form) {
          var subj_form = $(form).attr('data-subject');
          var csrf_token = $('meta[name="csrf-token"]').attr('content');
          var formdata = $("#do_form_request").serialize();
          $("#do_form_request")[0].reset();
          $.ajax({
              url: routes.postSend,
              type: 'POST',
              data: {
                  "_token" : csrf_token,
                  "data": formdata,
                  "subj": subj_form
              },
              success: function(data) {
                  $('.js_modal_footer[data-visible="request"]').addClass('modal__footer_hidden');
                  $('.js_forms_success[data-visible="request"]').show(300);
                  setTimeout(function() {
                      $.magnificPopup.close();
                      $('.js_forms_success[data-visible="request"]').hide();
                      $('.js_modal_footer[data-visible="request"]').removeClass('modal__footer_hidden');
                      $('.input_input').next('.input_placeholder').css({"left":"15px", "top":"14px","color":"#000"});
                      $('.input_input').removeClass('active_input');
                      if($('.size_select').hasClass('js_active')) {
                          $('.size_select').removeClass('js_active');
                          $('.size_select').next('.size_block').slideUp(400, function() {
                              $(this).removeClass('js_active');
                          });
                      }
                  }, 3000)
              }
          })
      }
  });
// Отправка формы из модалки на наличие товара, или его цену
// Отправка формы из модалки покупку в один клик
  $('#do_btn_click').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $("#do_form_click").submit();
 });

 $("#do_form_click").validate({
    errorElement: 'span',
    errorClass: 'span__error',
    ignoreTitle: true,
  errorPlacement: function(error, element) {
      error.appendTo($(element).parents('.input_block'));
  },
  highlight: function(element) {
      $(element).parents('.input_wrapper').addClass("forms__error");
  },
  unhighlight: function(element) {
      $(element).parents('.input_wrapper').removeClass("forms__error");
  },
    submitHandler: function(form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $("#do_form_click").serialize();
        $("#do_form_click")[0].reset();
        $.ajax({
            url: routes.postFastOrder,
            type: 'POST',
            data: {
                "_token" : csrf_token,
                "data": formdata,
                 "subj": "market_order"
            },
            success: function(data) {
                $('.js_modal_footer[data-visible="click_one"]').addClass('modal__footer_hidden');
                $('.js_forms_success[data-visible="click_one"]').show(300);
                setTimeout(function() {
                    $.magnificPopup.close();
                    $('.js_forms_success[data-visible="click_one"]').hide();
                    $('.js_modal_footer[data-visible="click_one"]').removeClass('modal__footer_hidden');
                    $('.input_input').next('.input_placeholder').css({"left":"15px", "top":"14px","color":"#000"});
                    $('.input_input').removeClass('active_input');
                }, 3000)
            }
        })
    }
});
// Отправка формы из модалки покупку в один клик
});
// --------------------- Форма отзывов -----------------------------
var Main = (function ($) {
    var labelText = $('.form__file-lt:lt(1)').text();
    $(function () {
        Main.init();
    })
    return {
        init: function () {
            var self = this;
            self.inputHadler();
            self.valid();
            self.formValidates();
            self.fileInputs();
        },
        fileInputs: function () {
            var fileInput = document.querySelectorAll('.input-file-js'),
            whiteList = [
                "text/plain",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.oasis.opendocument.text",
                "application/rtf",
                "application/pdf",
                "application/octet-stream",
                "image/jpeg",
                "image/png",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.oasis.opendocument.spreadsheet",
            ];
            if (fileInput) {
                var sizeError = document.querySelector('.form__size-error-js'),
                extensionError = document.querySelector('.form__extention-err-js');
                for (var j = 0; j < fileInput.length; j++) {
                    fileInput[j].addEventListener('change', function () {
                        var filename = this.parentNode.querySelector('.form__file-lt');
                        if (this.files.length) {
                            for (var i = 0; i < this.files.length; i++) {
                                if ((whiteList.indexOf(this.files[i].type) != -1 && this.files[i].size < 16000000) || ((this.files[i].name).indexOf('.dwg') != -1 && this.files[i].size < 16000000)) {
                                    filename.innerHTML = this.files[i].name.trim(8);
                                    filename.setAttribute('title', this.files[i].name);
                                    sizeError.classList.remove('js_show');
                                    extensionError.classList.remove('js_show');
                                } else {
                                    if (whiteList.indexOf(this.files[i].type) == -1) extensionError.classList.add('js_show');
                                    if (this.files[i].size > 16000000 || whiteList.indexOf(this.files[i].type) == -1) sizeError.classList.add('js_show');
                                    filename.setAttribute('title', '');
                                    filename.innerHTML = labelText;
                                    sizeError.classList.add('js_show');
                                    $(this).value = '';
                                    $(this).closest('.form__file-wr').find('.form__file-remove').addClass('js_hide');
                                }
                            }
                        } else {
                            filename.setAttribute('title', '');
                            filename.innerHTML = labelText;
                        }
                    });
                }
            }
        },
        valid: function (data) {
            function sendTextFields(item, formdata, file = '') {
                var dataForm;
                if (file != '') {
                    dataForm = {
                        data: formdata,
                        file: file,
                        subj: 'reviews',
                    }
                } else {
                    dataForm = {
                        data: formdata,
                        subj: 'reviews',
                    };
                };
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: dataForm,
                    success: function (data) {
                        $(item)[0].reset();
                        if (document.querySelectorAll('.input-file-js') != null) {
                            $('.input-file-js').val('');
                            $('.form__file-lt').attr('title', '').html('');
                            $(this).closest('.form__file-wr').find('.form__file-lt').html(labelText);
                            $('.form__file-remove ').addClass('js_hide');
                            $('.form__file-wr').find('.form__file-lt').html(labelText);
                        };
                        setTimeout(function () {
                            $(".review-form-js").find('.forms__success').slideDown(300);
                            $('.input_input').next('.input_placeholder').css({"left":"15px", "top":"14px","color":"#000"});
                            $('.input_input').removeClass('active_input');
                        }, 100);
                        setTimeout(function () {
                            $(".review-form-js").find('.forms__success').slideUp(300);
                        }, 3000);
                    }
                })
            };
            var item = data;

            $(item).validate({
                errorElement: 'span',
                errorClass: 'span__error',
                ignoreTitle: true,
                errorPlacement: function(error, element) {
                    error.appendTo($(element).parents('.input_block'));
                },
                highlight: function(element) {
                    $(element).parents('.input_wrapper').addClass("forms__error");
                },
                unhighlight: function(element) {
                    $(element).parents('.input_wrapper').removeClass("forms__error");
                },
                submitHandler: function (form) {
                    var csrf_token = $('meta[name="csrf-token"]').attr('content');
                    var formdata = $(item).serialize();
                    if (document.getElementById('photo_1').files.length || document.getElementById('photo_2').files.length) {
                        var inputs = $('.input-file-js');
                        let $data = {};
                        for (let i = 0; i < inputs.length; i++) {
                            let element = inputs[i];
                            let next_el = (inputs[i + 1]) ? (inputs[i + 1]) : null;
                            if (element != null && element.files.length) {
                                let reader = new FileReader();
                                reader.onload = function () {
                                    let key = 'file_' + i;
                                    let value = reader.result;
                                    $data[key] = value;
                                    if (next_el == null || !next_el.files.length) {
                                        setTimeout(function () {
                                            $.ajax({
                                                type: 'POST',
                                                url: routes.postLoadFile,
                                                data: {
                                                    "_token": csrf_token,
                                                    "data": $data,
                                                },
                                                success: function (response) {
                                                    var uploadedFile = response.file_name;
                                                    sendTextFields(item, formdata, uploadedFile);
                                                },
                                                error: function (response) {},
                                            });
                                        }, 100);
                                    }
                                };
                                reader.readAsDataURL(inputs[i].files[0]);
                            }
                        }
                    } else {
                        sendTextFields(item, formdata);
                    }
                }
            });
        },
        formValidates: function () {
            var buttons = document.getElementsByClassName('do_reviews_form'),
            form = document.getElementsByClassName('review-form-js');

            function submitForm(event) {
                event.preventDefault();
                event.stopPropagation();

                Main.valid(form);
                $(form).submit();

                return form;
            };
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', submitForm, true);
            };

        },
        inputHadler: function () {
            $('.input-file-js').change(function (e) {
                var labelRemove = $(this).closest('.form__file-wr').find('.form__file-remove');
                var fileName = $(this).closest('.form__file-wr').find('.form__file-lt');
                if (!$(this).val()) {
                    labelRemove.addClass('js_hide');
                    fileName.html("");
                    fileName.html(labelText);
                } else {
                    labelRemove.removeClass('js_hide');
                }
            })
            $('.form__file-remove').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).closest('.form__file-wr').find('.input-file').val('');
                $(this).closest('.form__file-wr').find('.input-file-js').val('');
                $(this).closest('.form__file-wr').find('.form__file-remove').addClass('js_hide');
                $(this).closest('.form__file-wr').find('.form__file-lt').html(labelText);
                $(this).closest('.form__file-wr').find('.form__file-lt').attr('title', '');
            });
        }
    }
})(jQuery);
// --------------------- Форма отзывов -----------------------------
